import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import { i18n } from "@/utils/i18n";
import store from "../store/index";
const routes = [
  {
    path: "/:lang?/:code?",
    name: "Home",
    component: Home,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach((from, to, next) => {
  if (from.params.code.length) {
    // @Alexis check if code exists somewhere ??
    const exists = true;
    if (exists) {
      store.commit("setCode", from.params.code);
    } else {
      alert("You need to provide a code");
    }
  }
  switch (from.params.lang) {
    case "external":
      i18n.global.locale = "politeFr";
      break;
    case "en":
      i18n.global.locale = "en";
      break;
    case "fr":
      i18n.global.locale = "fr";
      break;
    default:
      i18n.global.locale = "fr";
      next('/fr');
      break;
  }
  next();
});

export default router;
