<template>
  <svg
    width="25"
    height="23"
    viewBox="0 0 32 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M31.0554 12.422C29.9246 16.974 23.6667 24.037 18.2042 27.9374C17.5634 28.3983 16.7941 28.6462 16.0048 28.6462C15.2155 28.6462 14.4462 28.3983 13.8054 27.9374C8.39084 24.0465 2.07542 16.974 0.94459 12.422C-0.502493 6.61446 3.81959 -0.640122 11.0742 0.816545C12.8011 1.42298 14.4536 2.22349 16 3.20279C17.5489 2.22813 19.2008 1.42777 20.9258 0.816545C28.1613 -0.640122 32.5025 6.61446 31.0554 12.422Z"
      fill="#FF5A5A"
    />
  </svg>
</template>