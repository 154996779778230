import { createI18n } from "vue-i18n";

export const i18n = createI18n({
  locale: "en",
  messages: {
    fr: {
      intro: "Récupère ton NFT en 3 étapes :",
      one: "Crée un portefeuille compatible avec la cryptomonnaie ETH (Ethereum) en quelques secondes.",
      one_detail:
        "Si tu as déjà un wallet compatible Ethereum, type Metamask, passe directement à l’étape 2",
      one_action: "Créer mon wallet",
      two: "Connecte ton portefeuille pour stocker ton NFT",
      two_action: "Connecter mon wallet",
      three: "Clique ci-dessous pour minter (= générer) ton NFT",
      three_action: "Minter mon NFT",
      loading: "Transaction en cours...",
      loading_detail: "(Tu peux suivre sa progression",
      success: "Bravo ! Ton NFT est maintenant accessible dans ton wallet.",
      success_detail: "Tu peux le visualiser sur ",
      hidden_tab: "Par défaut, les NFT se trouvent dans l’onglet “Hidden” de ton compte. Tu peux les afficher publiquement",
      tweet: "en suivant ces quelques étapes",
      here: "ici",
      error: "Une errreur est survenue lors de la transaction",
      error_detail: "Tu peux trouver le détail ",
      contact: "Contacter le support",
      is_new_wallet: "Bravo !",
      is_new_wallet_detail: "Tu as bien créé ton wallet, tu peux le retrouver à tout instant sur",
      torus_app: "l'application Torus",
      "Already transferred or user unknown": "Ce NFT a soit déjà été créé ou n'existe pas"
    },
    politeFr: {
      intro: "Récupérez votre NFT en 3 étapes :",
      one: "Créez un portefeuille compatible  avec la cryptomonnaie ETH (Ethereum) en quelques secondes.",
      one_detail:
        "Si vous avez déjà un wallet compatible Ethereum, type Metamask, passez directement à l'étape 2",
      one_action: "Créer mon wallet",
      two: "Connectez votre portefeuille pour stocker votre NFT",
      two_action: "Connecter mon wallet",
      three: "Cliquez ci-dessous pour minter (= générer) votre NFT",
      three_action: "Minter mon NFT",
      loading: "Transaction en cours...",
      loading_detail: "(Vous pouvez suivre sa progression",
      success: "Bravo ! Votre NFT est maintenant accessible dans votre wallet.",
      success_detail: "Vous pouvez le visualiser sur ",
      hidden_tab: "Par défaut, les NFT se trouvent dans l’onglet “Hidden” de votre compte. Vous pouvez les afficher publiquement",
      tweet: "en suivant ces quelques étapes",
      here: "ici",
      error: "Une errreur est survenue lors de la transaction",
      error_detail: "Vous pouvez trouver le détail ",
      contact: "Contacter le support",
      is_new_wallet: "Bravo !",
      is_new_wallet_detail: "Vous avez bien créé votre wallet, vous pouvez le retrouver à tout instant sur",
      torus_app: "l'application Torus",
      "Already transferred or user unknown": "Ce NFT a soit déjà été créé ou n'existe pas"
    },
    en: {
      intro: "Get your NFT in three steps:",
      one: "Create a compatible wallet with ETH (Ethereum, a cryptocurrency), in a few seconds.",
      one_detail:
        "If you already have an Ethereum-compatible wallet, like Metamask, skip straight to step 2.",
      one_action: "Create my wallet",
      two: "Connect your wallet to store your NFT.",
      two_action: "Connect my wallet",
      three: "Click below to mint (generate) your NFT.",
      three_action: "Mint my NFT",
      loading: "Transaction underway",
      loading_detail: "(follow its progress",
      success: "Congratulations! Your NFT is now available in your wallet.",
      success_detail: "You can view it on ",
      hidden_tab: "NFTs are by default in the “Hidden” tab of your account. In order to display them publicly",
      tweet: "follow these steps",
      here: "here",
      error: "An error occured",
      error_detail: "You can find details ",
      contact: "Contact support",
      is_new_wallet: "Congratulations!",
      is_new_wallet_detail: "You’ve created a wallet and can find it at any time in",
      torus_app: "the Torus application",
      "Already transferred or user unknown": "This NFT has already been minted, or does not exist"
    },
  },
});
