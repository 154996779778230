<template>
  <div class="status">
    <transition name="fade" mode="out-in">
      <div v-if='state === 1' class="loading">
        <div class="loader">
          <div class="spinner"></div>
          <p class="mainText">{{$t('loading')}}</p>
        </div>
      </div>
    </transition>
    <transition name="fade" mode="out-in">
      <div v-if="state === 2" class="success">
        <p class="mainText">
          {{$t('success')}}
        </p>
        <p class="subText">{{$t('success_detail')}} <a target="_blank" :href="infos.openseaUrl">OpenSea</a>.</p>
        <p class="subText">{{$t('hidden_tab')}} <a target="_blank" href="https://twitter.com/opensea/status/1445166428693450752">{{$t('tweet')}}</a>.</p>
      </div>
    </transition>
    <transition name="fade" mode="out-in">
      <div v-if="state=== 3" class="error">
        <p class="mainText">
         {{$t('error')}}
        </p>
        <p class="subText">{{infos.errorMessage}}<br>
        </p>
      </div>
    </transition>
  </div>
</template>
<script>
import { computed } from '@vue/runtime-core';
import { useStore } from 'vuex';
export default {
  props: ["state"],
  setup() {
    const store = useStore()
    return {
      infos: computed(() => store.getters.infos)
    }
  }
};
</script>
