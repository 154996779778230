<template>
  <div class="info">
    <svg
    width="43"
    height="43"
    viewBox="0 0 43 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.4999 0.540894C9.92472 0.540894 0.54071 9.92493 0.54071 21.5002C0.54071 33.0772 9.92544 42.4594 21.5007 42.4594C33.0766 42.4594 42.4588 33.0772 42.4588 21.5002C42.4588 9.92493 33.0766 0.540894 21.4996 0.540894H21.4999ZM22.3864 8.83165C23.9079 8.83165 25.1409 10.0654 25.1409 11.5869C25.1409 13.1084 23.908 14.3422 22.3864 14.3422C20.8649 14.3422 19.6311 13.1084 19.6311 11.5869C19.6311 10.0654 20.8649 8.83165 22.3864 8.83165ZM24.8564 33.2094C24.8564 33.2094 22.6857 35.0295 19.9512 33.6568C19.3442 33.3533 18.8315 32.8266 18.5041 32.2468C17.5755 30.6045 17.9327 28.8423 17.9327 28.8423L18.2057 26.6816L18.9144 21.0468L16.9843 21.0889C16.0805 21.1096 15.3345 20.3927 15.3147 19.4897C15.299 18.7884 15.7331 18.1798 16.3499 17.9343L21.2956 15.9868C22.4732 15.523 23.8028 16.1018 24.2659 17.2794C24.4428 17.7267 24.4684 18.1931 24.3676 18.628L24.3023 18.9215L22.3681 27.4148L21.8852 29.5383C21.8141 29.8583 21.8033 29.9311 21.7826 30.0989C21.7305 31.0548 23.0899 30.3379 23.0899 30.3379C23.9061 29.8871 24.9331 30.1857 25.3813 31.0019C25.804 31.7686 25.5592 32.7229 24.8562 33.2091L24.8564 33.2094Z"
      fill="#2E3031"
      fill-opacity="0.25"
    />
  </svg>
  <div class="detail">
    <div class="detail-content">
      <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Deserunt architecto laudantium rerum placeat illum adipisci optio fugiat repudiandae quam, aliquid nobis explicabo deleniti mollitia corporis suscipit, quaerat magni est tempora!</p>
    </div>
  </div>
  </div>
  
</template>