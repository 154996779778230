<template>
  <button v-if="!wallet.address" class="btn" @click="connect">
    {{ $t("two_action") }}
  </button>
  <a
    v-else
    class="address"
    :href="'https://polygonscan.com/address/' + wallet.address"
    target="blank"
    >{{ truncate(wallet.address, 13, "...") }}</a
  >
</template>
<script>
import truncate from '@/utils/truncate'
import WalletConnectProvider from "@walletconnect/qrcode-modal";
import WalletConnect from '@walletconnect/web3-provider'
import Torus from "@toruslabs/torus-embed";
import Web3Modal from "web3modal";
import { WalletLink } from "walletlink";
import Toastify from "toastify-js";
import "toastify-js/src/toastify.css";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { computed, onMounted } from '@vue/runtime-core';
import { isMobile } from 'mobile-device-detect';


export default {
  setup(props, { emit }) {
      const {ethereum} = window
       onMounted(async () => {
            if(ethereum && (ethereum.isCoinbaseWallet || ethereum.isMetaMask) && isMobile) {
            await connect('auto')
            document.querySelector('.step-two').scrollIntoView({
                behavior:'smooth'
            })
        }
       })
      // Use store to save connection result and user's wallet address
    const store = useStore();
    const router = useRouter();
    const providerOptions = {
      walletconnect: {
        package: WalletConnect,
        options: {
          infuraId: process.env.VUE_APP_INFURA_ID
        },
      },
      torus: {
        package: Torus,
        options: {
          networkParams: {
            host:
              "https://polygon-mainnet.infura.io/v3/" + process.env.VUE_APP_INFURA_ID,
            chainId: 137,
          }
        }
      },
      "custom-walletlink": {
        display: {
          logo:
            "https://raw.githubusercontent.com/walletlink/walletlink/master/web/src/images/wallets/coinbase-wallet.svg", // Path to wallet link logo. Source https://github.com/walletlink/walletlink/blob/master/web/src/images/wallets/coinbase-wallet.svg
          name: "Coinbase Wallet",
          description: "Continuer avec Coinbase Wallet"
        },
        options: {
          appName: "Brut.", // Your app name
          networkUrl: `https://polygon-mainnet.infura.io/v3/${process.env.VUE_APP_INFURA_ID}`,
          chainId: 137
        },
        package: WalletLink,
        connector: async (_, options) => {
          const { appName, networkUrl, chainId } = options;
          const walletLink = new WalletLink({
            appName
          });
          const provider = walletLink.makeWeb3Provider(networkUrl, chainId);
          await provider.enable();
          return provider;
        }
      }
    };
    const mobileProviderOptions = {
      "custom-walletconnect": {
        package: WalletConnectProvider,
        display: {
          name: "Mobile Wallet",
          description: "Choisissez votre application"
        },
        connector: async (_, options) => {
          WalletConnectProvider.open(`https://nft.brut.media${router.currentRoute.value.path}`)
        }

      },
      torus: {
        package: Torus,
        options: {
          networkParams: {
            host:
              "https://polygon-mainnet.infura.io/v3/" + process.env.VUE_APP_INFURA_ID,
            chainId: 137,
          }
        }
      },
      "custom-metamask": {
        display: {
          logo:
            "https://nft.brut.media/metamask.png", // Path to wallet link logo. Source https://github.com/walletlink/walletlink/blob/master/web/src/images/wallets/coinbase-wallet.svg
          name: "Metamask",
          description: "Continuer sur l'application"
        },
        options: {
          appName: "Brut.", // Your app name
          networkUrl: `https://polygon-mainnet.infura.io/v3/${process.env.VUE_APP_INFURA_ID}`,
          chainId: 137
        },
        package: WalletLink,
        connector: async (_, options) => {
          window.open(`https://metamask.app.link/dapp/nft.brut.media${router.currentRoute.value.path}`)
        }
      },
      "custom-walletlink": {
        display: {
          logo:
            "https://raw.githubusercontent.com/walletlink/walletlink/master/web/src/images/wallets/coinbase-wallet.svg", // Path to wallet link logo. Source https://github.com/walletlink/walletlink/blob/master/web/src/images/wallets/coinbase-wallet.svg
          name: "Coinbase Wallet",
          description: "Continuer sur l'application"
        },
        options: {
          appName: "Brut.", // Your app name
          networkUrl: `https://polygon-mainnet.infura.io/v3/${process.env.VUE_APP_INFURA_ID}`,
          chainId: 3
        },
        package: WalletLink,
          connector: async (_, options) => {
          const { appName, networkUrl, chainId } = options;
          const walletLink = new WalletLink({
            appName,
          });
          const provider = walletLink.makeWeb3Provider(networkUrl, chainId);
          await provider.enable();
          return provider;
        },
      }
    };
    const connect = async (mode) => {
      const web3Modal = new Web3Modal({
        network: "matic",
        cacheProvider: isMobile,
        disableInjectedProvider: false,
        providerOptions: isMobile ? mobileProviderOptions : providerOptions,
      });
      if(!isMobile) web3Modal.clearCachedProvider();
      try {
        const connection = await web3Modal.connect();
        let address = null;
        if (connection.isWalletConnect) address = connection.accounts[0];
        else if (
          connection.isWalletLink ||
          connection.isMetaMask ||
          connection.isTorus ||
          connection.isCoinbaseWallet
        )
          {
              address = connection.selectedAddress;
          }
        store.commit("setWallet", { address, connection });
        emit("complete");
        if(mode !== 'auto') {
          document.querySelector('.step-three').scrollIntoView({
                behavior:'smooth'
            })
        }
      } catch (e) {
        Toastify({
          text: "An error occured while linking wallet, please try again",
          duration: 3000,
          gravity: "top",
          position: "center",
          backgroundColor: "indianred",
          onClick: function() {}
        }).showToast();
      }
    };
    return {
      connect,
      wallet:computed(() => store.getters.wallet),
      truncate
    };
  }
};
</script>
