<template>
  <div class="home">
    <h1 class="title">{{$t("intro")}}</h1>

    <div :class="wallet.address ? 'step disabled' : 'step'">
      <div>
        <h2 class="number">1</h2>
      </div>
      <div>
        <p class="mainText">
          {{ $t("one")}} 
        </p>
        <p class="subText">
         {{$t("one_detail")}}
        </p>
        <new-wallet/>
      </div>
    </div>

    <div class="step step-two">
      <div>
        <h2 class="number">2</h2>
      </div>
      <div>
        <p v-if='!wallet.new' class="mainText">{{$t("two")}}</p>
        <p v-else class="mainText">{{$t("is_new_wallet")}}</p>
        <p v-if='wallet.new' class="subText">{{$t("is_new_wallet_detail")}} <a href="https://app.tor.us/" target="_blank">{{$t("torus_app")}}</a></p>
        <connector/>
      </div>
    </div>

    <div class="step step-three">
      <div>
        <h2 class="number">3</h2>
      </div>
      <div>
        <p class="mainText" v-if="state!==2 && state!==3">
          {{$t("three")}} 
        </p>
        <button @click="handleMint" :disabled="!wallet.address" v-if="state!==2 && state!==3" class="btn">{{$t("three_action")}}</button>
        <status :state="state"/>
      </div>
    </div>
  </div>
</template>

<script>
import Connector from '../components/Connector.vue'
import Status from '../components/Status.vue'
// @ is an alias to /src
import Info from '../components/Info'
import { useStore } from 'vuex'
import { computed, ref } from '@vue/runtime-core'
import NewWallet from '../components/NewWallet.vue'
export default {
  name: 'Home',
  components: {
    Info,
    Connector,
    Status,
    NewWallet
  },
  setup() {
    const connecting = ref(false)
    const store = useStore()
    const state = ref(0) 
    /**
     * 0 : function not called
     * 1 : loading
     * 2 : success
     * 3 : failure
     */
    const handleMint = async () => {
      state.value = 1;
      try {
        await store.dispatch('mint')
        state.value = 2;
      }
      catch {
        state.value = 3
      }

    }
    return {
      connecting,
      state,
      handleMint,
      wallet: computed(() => store.getters.wallet),
    }
  },
}
</script>
