<template>
    <button @click="handleCreate" :disabled="wallet && wallet.address ? true : null" class="btn">{{$t('one_action')}}</button>
</template>
<script>
import Torus from "@toruslabs/torus-embed";
import { useStore } from 'vuex';
import { toRaw } from '@vue/reactivity';
import { computed, onMounted } from '@vue/runtime-core';
export default {
    setup() {
    const store = useStore()
      const connector = new Torus()
      onMounted(async () => {
          await connector.init({
                        enableLogging: true,
              showTorusButton: false,

          })
      })
      const handleCreate = async () => {
          
          const user = await connector.login()
          const infos = await connector.getUserInfo()
          console.log(infos)
          store.commit('setWallet', {address:user[0], connection: toRaw(connector.provider), new:true})
         document.querySelector('.step-two').scrollIntoView({
                behavior:'smooth'
            })

      }
      const handleConnect = async (verifier) => {
            const user = await connector.login({verifier:'twitter' })
          store.commit('setWallet', {address:user[0], connection: toRaw(connector.provider)})
         document.querySelector('.step-two').scrollIntoView({
                behavior:'smooth'
            })
      }
      return {
          handleCreate,
          handleConnect,
          wallet:computed(() => store.getters.wallet)
      }
    },
}
</script>