import { createStore } from "vuex";
import axios from "axios";
export default createStore({
  state: {
    wallet: {},
    infos: {},
    code: null,
  },
  getters: {
    wallet(state) {
      return state.wallet;
    },
    infos(state) {
      return state.infos;
    },
  },
  mutations: {
    setWallet(state, wallet) {
      state.wallet = wallet;
    },
    setInfos(state, infos) {
      state.infos = { ...state.infos, ...infos };
    },
    setCode(state, code) {
      state.code = code;
    },
  },
  actions: {
    mint(ctx) {
      console.log(ctx);
      // clain function
      return new Promise(async (resolve, reject) => {
        try {
          const tx = await axios.post("https://api.renaissance.land/br3t", {
            code: ctx.state.code,
            wallet: ctx.state.wallet.address,
          }).catch(function (error) {
            if(error.response){
              console.log(error.response.data.error.message)
              ctx.commit("setInfos", {
                errorMessage: error.response.data.error.message,
              });
            }
            reject();
          });
          ctx.commit("setInfos", {
            transactionUrl: `https://polygonscan.com/tx/${tx.tx}`,
          });
          ctx.commit("setInfos", {
            openseaUrl: `https://opensea.io/account?tab=private`,
          });
          resolve();
        } catch(e) {
          reject();
        }
      });
    },
  },
  modules: {},
});
